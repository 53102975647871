import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Layout } from "../components/layout"
import SEO from "../components/seo"

import siteTitlePng from "../assets/images/site-title.png"

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      allGhostPost(sort: { order: DESC, fields: published_at }, limit: 100) {
        nodes {
          id
          slug
          feature_image
          tags {
            name
          }
          title
          excerpt
          authors {
            profile_image
          }
          published_at(formatString: "MMMM DD, YY")
        }
      }
    }
  `)

  const posts = data.allGhostPost.nodes
  const PostViews = posts.map((post, index, posts) => {
    if (index == 0) {
      return <SinglePost post={post} key={post.id} />
    }
    // check if odd
    if (Math.abs(index % 2) === 1) {
      const _posts = [posts[index], posts[index + 1]]

      return (
        <div className="two-post-container row my-3 py-3">
          {_posts.map(
            _post =>
              _post && (
                <div className="col-md-6 mb-2">
                  <DoublePost post={_post} key={post.id} />
                </div>
              )
          )}
        </div>
      )
    }
  })

  return (
    <Layout>
      <SEO />

      <div
        className="pb-3 no-home-header"
        style={{
          height: 420,
          backgroundSize: "cover",
        }}
      />

      <div className="blog-header">
        <div className="inner container d-flex flex-column justify-content-center align-items-center">
          {/* <div className="site-title">
            <img src={siteTitlePng} />
          </div> */}
          <div className="site-descr">
            Ecart.chat enables facebook messenger chatbot for your ecommerce
            stores to reduce open carts and regain sales by re-targeting
            customers
          </div>
        </div>
      </div>

      <div className="page_content container">
        <div className="inner">{PostViews}</div>
      </div>
    </Layout>
  )
}

const SinglePost = ({ post }) => {
  return (
    <div className="card single-post">
      <div className="card-body d-lg-flex p-0">
        <div className="col-lg-7 p-0">
          <Link to={`blog/${post.slug}`} className="post-head-link">
            <img className="post-image" src={post.feature_image} />
          </Link>
        </div>
        <div className="col-lg-5 p-0">
          <div className="post-content py-2 px-3">
            <a className="text-muted post-category" href="#" />
            <ul className="post-categories">
              <a className="text-muted post-category" href="#"></a>
              <li>
                <a className="text-muted post-category" href="#" />
                <a rel="category tag">{post.tags[0] && post.tags[0].name} </a>
              </li>
            </ul>

            <Link to={`blog/${post.slug}`} className="post-head-link">
              <h3 className="post-head my-3">{post.title}</h3>
            </Link>
            <div className="post-card-excerpt">
              {post.excerpt.slice(0, 400).concat(" ...")}
            </div>
            <div className="post-card-meta d-flex justify-content-between align-items-center p-3">
              <a href="#">
                <img
                  className="author-img"
                  src={post.authors[0].profile_image}
                />
              </a>
              <span className="reading-time text-muted small">
                {post.published_at}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DoublePost = ({ post }) => {
  return (
    <div className="card">
      <div className="card-body row p-0">
        <a style={{ width: "100%" }}>
          <div className="col-12">
            <Link to={`blog/${post.slug}`} className="post-head-link">
              <img className="post-image" src={post.feature_image} />
            </Link>
          </div>
        </a>
        <div className="col-sm-12">
          <div className="post-content p-4">
            <a className="text-muted" href="#" />
            <ul className="post-categories">
              <a className="text-muted" href="#"></a>
              <li>
                <a className="text-muted" href="#" />
                <a rel="category tag">{post.tags[0] && post.tags[0].name}</a>
              </li>
            </ul>
            <Link to={`blog/${post.slug}`} className="post-head-link">
              <h3 className="post-head my-3">{post.title}</h3>
            </Link>
            <p className="post-card-excerpt">{post.excerpt.slice(0, 400).concat(" ...")}</p>
            <div className="post-card-meta d-flex justify-content-between align-items-center px-1">
              <a href="#">
                <img
                  className="author-img"
                  src={post.authors[0].profile_image}
                />
              </a>
              <span className="reading-time text-muted small">
                {post.published_at}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
